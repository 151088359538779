/* Human Support - Horeca - CSS Document */
@use '@angular/material' as mat;
/* ---------------------------------------------------
    IMPORT
----------------------------------------------------- */
/* @import "https://fonts.googleapis.com/css?family=Ubuntu:400,500,700"; */
/* @import "https://fonts.googleapis.com/css?family=Lato:300,400,700"; */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';


/* ------------------------------------------------------------------     V1   ------------------------------------------------------------------------------------- */
/* GLOBAL RULES */
.controls > input[readonly] {
  background-color: rgba(203, 203, 203, 0.15);
}

.controls > input[readonly]:hover {
  border: 2px dashed #c6c6c6;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: #fafafa;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.shadowbox {
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  padding-top: 16px;
  margin-bottom: 25px;
}

.redHead {
  background-color: #F44E4E;
}

.greenBox {
  background: #fff;
  border-radius: 25px;
  border: 1px solid #F46250;
}

.headingGreenBox {
  position: relative;
  top: -19px;
  padding-left: 5px;
  padding-right: 7px;
  display: table-cell;
  background: #fff;
  font-family: 'Ubuntu', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 1.4em;
  line-height: 35px;
  color: #F46250;
}

span.greenFooter {
  color: #F46250;
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Ubuntu', sans-serif;
}

span.blackFooter {
  color: #212529;
  font-size: 1.2em;
  font-family: 'Open Sans', sans-serif;
}

.colorline {
  border-bottom: 1px solid #F44E4E;
  margin-bottom: 33px;
}

.littleText {
  font-size: 0.8em;
  font-weight: 400;
}

.redbox {
  padding-left: 0;
  border-radius: 4px;
  border: 1px solid #F44E4E;
}

span.weekWhite {
  font-size: 1em;
  font-weight: 700;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  border-bottom-left-radius: 3px;
  padding: 0 0;
  overflow: visible;
}

.alert-success {
  color: white;
  background-color: #F46250;
  border-color: #1D2E48;
}

.badge-success {
  color: #fff;
  background-color: #F46250;
}

.vertical-align {
  vertical-align: middle;
}

.text-justify {
  text-align: justify;
}

.card-footer {
  font-size: 0.75em;
}

/* ---------------------------------------------------
    GLOBAL FORMS STYLE
----------------------------------------------------- */

.form-control {
  font-family: 'Open Sans', sans-serif;
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 0.8em;
  line-height: 1.5;
  color: #1D2E48;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #808495;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:disabled, .form-control[readonly] {
  background-color: rgba(23, 162, 184, 0.25);
}

.form-group {
  margin-bottom: 20px;
}

.controls {
  text-align: left;
  position: relative;
}

.controls input[type="text"],
.controls input[type="email"],
.controls input[type="number"],
.controls input[type="date"],
.controls input[type="tel"],
.controls input[type="time"],
.controls textarea,
.controls button,
.controls select {
  padding: 12px;
  font-size: 14px;
  border: 2px solid #c6c6c6;
  width: 100%;
  margin-bottom: 18px;
  color: #000;
  font-family: 'Open Sans', 'sans-serif';
  font-size: 16px;
  font-weight: 500;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.controls input[type="text"]:focus, .controls input[type="text"]:hover,
.controls input[type="email"]:focus,
.controls input[type="email"]:hover,
.controls input[type="number"]:focus,
.controls input[type="number"]:hover,
.controls input[type="date"]:focus,
.controls input[type="date"]:hover,
.controls input[type="tel"]:focus,
.controls input[type="tel"]:hover,
.controls input[type="time"]:focus,
.controls input[type="time"]:hover,
.controls textarea:focus,
.controls textarea:hover,
.controls button:focus,
.controls button:hover,
.controls select:focus,
.controls select:hover {
  outline: none;
  border-color: #9FB1C1;
}

.controls input[type="text"]:focus + label, .controls input[type="text"]:hover + label,
.controls input[type="email"]:focus + label,
.controls input[type="email"]:hover + label,
.controls input[type="number"]:focus + label,
.controls input[type="number"]:hover + label,
.controls input[type="date"]:focus + label,
.controls input[type="date"]:hover + label,
.controls input[type="tel"]:focus + label,
.controls input[type="tel"]:hover + label,
.controls input[type="time"]:focus + label,
.controls input[type="time"]:hover + label,
.controls textarea:focus + label,
.controls textarea:hover + label,
.controls button:focus + label,
.controls button:hover + label,
.controls select:focus + label,
.controls select:hover + label {
  color: #F46250;
  cursor: text;
}

.controls .fa-sort {
  position: absolute;
  right: 10px;
  top: 17px;
  color: #999;
}

.controls select {
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.controls label {
  position: absolute;
  left: 8px;
  top: -15px;
  width: auto;
  color: #d2d0d0;
  font-style: italic;
  font-size: 14px;
  display: inline-block;
  padding: 4px 10px;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0);
  -moz-transition: color 0.3s, top 0.3s, background-color 0.8s;
  -o-transition: color 0.3s, top 0.3s, background-color 0.8s;
  -webkit-transition: color 0.3s, top 0.3s, background-color 0.8s;
  transition: color 0.3s, top 0.3s, background-color 0.8s;
  background-color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.controls label.active {
  top: -15px;
  color: #555;
  background-color: white;
  width: auto;
  font-style: normal;
}

.controls textarea {
  resize: none;
  height: 332px;
}

.modal .controls textarea {
  resize: none;
  height: 132px;
}

/* ---------------------------------------------------
    GLOBAL MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {

  #sidebar.active {
    margin-left: 0;
  }

  #content {
    width: 100%;
  }

  #content.active {
    width: calc(100% - 250px);
  }

  #sidebarCollapse span {
    display: none;
  }
}

/* ---------------------------------------------------
    GLOBAL CALENDAR
----------------------------------------------------- */
.fc-time-grid-event .fc-time {
  font-size: 1.20em;
  font-weight: 500;
  white-space: nowrap;
}

.fc h2 {
  color: #1D2E48;
}

.fc-scrollgrid-section-header {
  background:  #F46250;
  color: #fff;
}

.fc thead {
  background:  #F46250;
  color: #fff;
}

.fc-timeline-header-row{
  background:  #F46250;
}

.fc-cell-text {
  left: 0 !important;
}

.fc-unthemed th {
  border-color: #F44E4E;
}

.costCenter-chip-list {
  width: 100%;
}

.costCenter-head {
  margin-top: 2em;
}




/* ------------------------------------------------------------------     NEW   ------------------------------------------------------------------------------------- */

/* ---------------------------------------------------
    GLOBAL RULES
----------------------------------------------------- */
/* Vertical Align  */
.verticalAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

span.whiteBox {
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  border: 3px solid white;
  padding: 15px 50px;
}


/* ---------------------------------------------------
    GLOBAL TITLES
----------------------------------------------------- */
h1.heading {
  padding-top: 10px;
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  color: #fff;
}

h3.heading {
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  color: #1D2E48;
}

h4 {
  font-family: 'Urbane', sans-serif;
  font-weight: 500;
  font-size: 1.4em;
  line-height: 35px;
  color: #fff;
  font-style: normal;
}

h4.loginTitle {
  text-align: center;
  font-family: 'Urbane', sans-serif;
  padding-top: 0.4em;
}

h5 {
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  color: #1D2E48;
}

h5.heading {
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  color: #1D2E48;
  text-align: left;
  border-bottom: 1px solid #1D2E48;
  padding-bottom: 3px;
  margin-bottom: 20px;
}

/* ---------------------------------------------------
    GLOBAL TEXT
----------------------------------------------------- */
/* General text font  */
p,
.pwstxt,
div,
mat-form-field mat-label,
mat-form-field input,
card-footer /* Login: Privacy policy */
{
  font-family: 'Open Sans', sans-serif;
}

/* Sidebar font */
nav#sidebar li {
  font-family: 'Urbane', sans-serif;
}

/* Error message color  */
mat-error {
  color: #F46250 !important;
}

/* Contracts Text */
span.contractsHeaderInfo {  /* Contract header: information headers */
  color: #F46250;
  font-size: 0.8em;
  font-weight: 400;
}

span.heading {  /* Contract people: people information headers */
  font-size: 1em;
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  color: #F46250;
}

span.orangeText {
  color: #F46250;
  font-size: 0.8em;
  font-weight: 400;
}

/* Text White  */
.txtWhite {
  color: #fff;
}

/* Text Align  */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}


/* ---------------------------------------------------
    GLOBAL BUTTONS, TOGGLE, CHECK-BOXES & RADIO BUTTONS
----------------------------------------------------- */
/* Sidebar Toggle button */
.overflowButton, .overflowButton:focus {
  background: #F46250 !important;
  box-shadow: none !important;
  border: none !important;
}


/* Buttons with btn-danger Class */
.btn-danger {
  background: #1D2E48 !important;
  font-weight: 700;
  border: none;
}

.btn-danger:hover {
  background: #F46250;
}

/* Mat-flat button  */
a.mat-flat-button,
button.mat-flat-button, 
div button.mat-flat-button,
div.heading button.mat-flat-button,
div.pl-1 a.mat-flat-button, /* Operating Unit: Back button  */
div.pl-3 a.mat-flat-button, /* Request error detail: Back button  */
div.pr-1 button.mat-flat-button, /* Prestations: Release Payroll button  */
div.col-2 button.mat-flat-button, /* Customers: Subsidiary Adress  */
div.mb-1 button.mat-flat-button, /* Requests: Edit request: Add event period dialog */
td button.mat-flat-button  {
  background: #1D2E48 !important;
  color: white !important;
  font-family: 'Urbane', sans-serif !important;
  border-radius: 25px !important;
}

/* Mat-flat button: hover  */
a.mat-flat-button:hover,
button.mat-flat-button:hover, 
div button.mat-flat-button:hover,
div.heading button.mat-flat-button:hover,
div.pl-1 a.mat-flat-button:hover, /* Operating Unit: Back button  */
div.pl-3 a.mat-flat-button:hover, /* Request error detail: Back button  */
div.pr-1 button.mat-flat-button:enabled:hover, /* Prestations: Release Payroll button, Requests: Edit request: Save/validate buttons  */
div.col-2 button.mat-flat-button:hover, /* Customers: Subsidiary Adress  */
div.mb-1 button.mat-flat-button:hover, /* Requests: Edit request: Add event period dialog */
td button.mat-flat-button:hover  {
  background: #F46250 !important;
  color: white !important;
  /* background-image: -webkit-linear-gradient(left,#e6047f,#f46250); */
}

/* Mat-flat button: disabled  */
a.mat-flat-button:disabled,
button.mat-flat-button:disabled, 
div button.mat-flat-button:disabled,
div.heading button.mat-flat-button:disabled,
div.pl-1 a.mat-flat-button:disabled, /* Operating Unit: Back button  */
div.pl-3 a.mat-flat-button:disabled, /* Request error detail: Back button  */
div.pr-1 button.mat-flat-button:disabled, /* Prestations: Release Payroll button  */
div.col-2 button.mat-flat-button:disabled, /* Customers: Subsidiary Adress  */
div.mb-1 button.mat-flat-button:disabled, /* Requests: Edit request: Add event period dialog */
td button.mat-flat-button:disabled,
div button.orangeButton:disabled, /* Customers: Customer view: Deactivate customer + Customers: Subsidiary Adress: Close*/
div.pr-1 button.orangeButton:disabled, /* Requests: Edit request: Delete request */
div.mb-1 button.orangeButton:disabled, /* Requests: Edit request: Add event period dialog */
div.mb-4 button.orangeButton:disabled /* Customer view: Create Account + Worker view: Create Account */ {
  background: #4258 !important;
  opacity: 80%;
}

/* Excel Export Buttons */
div.heading button.excel {
  background-color: #008641 !important;
  color: white;
  font-family: 'Urbane', sans-serif;
  padding: 0 1.2em;
  float: right;
  border-radius: 25px;
  margin-right: 1em;
  margin-bottom: 1em;
}

div.heading .excel:hover {
  background: #F46250 !important;
  color: white;
}

/* Orange buttons */
div button.orangeButton, /* Customers: Customer view: Deactivate customer + Customers: Subsidiary Adress: Close*/
div.pr-1 button.orangeButton, /* Requests: Edit request: Delete request */
div.mb-1 button.orangeButton, /* Requests: Edit request: Add event period dialog */
div.mb-4 button.orangeButton /* Customer view: Create Account + Worker view: Create Account */
{
  background: #F46250 !important;
  color: white;
  border-radius: 25px;
  font-family: 'Urbane', sans-serif;
}

/* Orange buttons: hover */
div button.orangeButton:hover, /* Customers: Customer view: Deactivate customer + Customers: Subsidiary Adress: Close*/
div.pr-1 button.orangeButton:hover, /* Requests: Edit request: Delete request */
div.mb-1 button.orangeButton:hover, /* Requests: Edit request: Add event period dialog */
div.mb-4 button.orangeButton:hover /* Customer view: Create Account + Worker view: Create Account */
{
  background: #1D2E48 !important;
}

/* Customers: Customer Edit: (Show) Subsidiary Adress Button */
button.mat-stroked-button {
  color: #1D2E48;
  opacity: 1;
  font-family: 'Urbane', sans-serif;
}

/* Check-box */
div.mb-3 mat-checkbox /* Login */ {
  font-family: 'Open Sans', sans-serif;
}

.mat-checkbox-checked .mat-checkbox-background /* Login, Requests */ {
  background-color: #F46250 !important;
  font-family: 'Open Sans', sans-serif;
}

/* Radio buttons */
mat-radio-button, /* Customers: Customer Edit: Legal data Radio button Yes/no */
div.form-row mat-label.green-i-1 /* Customers: Customer Edit: Legal data Radio button Label */
{
  font-family: 'Open Sans', sans-serif;
  color: #1D2E48;
}

mat-radio-button.mat-radio-checked .mat-radio-outer-circle
{
  background: white !important;
  border: 1px solid #1D2E48 !important;
}

mat-radio-button.mat-radio-checked .mat-radio-inner-circle
{
  background: #F46250 !important;
}


/* ---------------------------------------------------
    GLOBAL BADGE, CHIP, etc.
----------------------------------------------------- */
/* Requests: Edit request,
Customers: Edit Customer 
Users: Edit User */
div.headingMT mat-chip.chipNumber,
div.mt-3 mat-chip.chipNumber, /* Customers: Customer view */
div.mb-3 mat-chip.chipNumber, /* Operating-Unit: Edit Operating-Unit */
div.col-12 mat-chip.chipNumber, /* Customer: New customer: Cost Center chip */
mat-card .mat-badge-content
{
  background: #F46250;
  color: white;
}
/* ====> For mat-chip, add: color="X" class="chipNumber" in the HTML */


/* Planning */
span .mat-badge-content {
  background: #F46250;
}

/* Dashboard, Users  */
h5.heading .mat-badge-content,
.nav-link .mat-badge-content {
  background: #F46250;
  font-size: 75%;
}


/* ---------------------------------------------------
    GLOBAL FORMS (Input, Mat-form-field, Mat-select)
----------------------------------------------------- */
/* Elastic search */
.elasticSearch.mat-focused {
  width: 25em;
}

.elasticSearch .mat-icon {
  color: #F46250;
}

/* Label in Form's input */
mat-label,
mat-form-field.costCenter-chip-list .mat-form-field-label /* Customer: New Customer: Cost Center */
{
  color: #4258 !important;
}

/* Label if focused in Form's input */
.mat-form-field.mat-focused mat-label {
  color: #4258 !important;
}

/* Label if error in Form's input */
.mat-form-field-invalid mat-label,
.mat-form-field-invalid .mat-form-field-required-marker, /* "*" invalid marker */ 
.mat-form-field-invalid.mat-focused mat-label
{
  color: #F46250 !important;
}

/* Insertion point color in Form's input */
mat-form-field input {
  caret-color: #F46250  !important;
}

/* Mandatory fields (*) in Form's input */
.mat-form-field-label {
  color: #F46250 !important;
}

/* Colorized borders for Form's input */
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #1D2E48;
  opacity: 0.6 !important;
}

/* Colorized borders for Form's input when empty (placeholder not above) or focused */
.mat-form-field.mat-form-field-hide-placeholder .mat-form-field-outline-thick,
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
  color: #4258 !important;
  box-shadow: 6px 6px 6px #4258
}


/* Colorized borders for Form's input when focused */
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
  opacity: 1 !important;
}

/* Colorized border when input error */
.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick{
  color: #F46250 !important;
  opacity: 0.8 !important;
}

/* Colorized underline when focused on an input (ex: Customers: Edit Customer) */
.mat-form-field-ripple
{
  background: #F46250  !important;
 }

/* Mat-select colorized labels & arrow  */
.mat-option.mat-selected .mat-option-text, .mat-select-arrow-wrapper .mat-select-arrow {
  color: #F46250 !important;
}

/* Mat-Form-Field Custom width (Profil/Customer compo) */
.mat-form-field {
  width: 90%;
}

.mediumField {
  width: 60%;
}

/* ---------------------------------------------------
GLOBAL TABLES
----------------------------------------------------- */
table th {
  font-family: 'Urbane', sans-serif !important;
  color: #1D2E48 !important;
}

td {
  font-family: 'Open Sans', sans-serif !important;
  color: #1D2E48 !important;
}

td.mat-cell {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.table {
  width: 100%;
  max-width: 100%;

  th, td {
    vertical-align: middle;
    text-align: center;
  }

  thead th {
    vertical-align: middle;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }

}

table .okStatut {
  background: #00CE7D !important;
  border-left: 3px solid #26c84c;;
}

table .busyStatut {
  background: #FF9D00 !important;
  border-left: 3px solid #FF9D00;
}

table .middleStatut {
  background: #ffa200 !important;
  border-left: 3px solid #ffa200;
}

table .inactiveStatut {
  background: #F44E4E !important;
  border-left: 3px solid #F44E4E;
}

table .busyColor {
  color: #FF9D00 !important;
  font-weight: 700 !important;
}

table .activeColor, 
.green {
  color: #26c84c !important;
  font-weight: 700 !important;
}

table .inactiveColor, 
.inactiveColor {
  color: #F44E4E !important;
  font-weight: 700 !important;
}

table .middleColor, 
.middleColor {
  color: #ffa200 !important;
  font-weight: 700 !important;
}

.orange {
  color: #FF9D00;
}

td .validate {
  border-left: 3px solid #26c84c;;
}

td .warning {
  border-left: 3px solid #FFA500;;
}

td .toValidate {
  border-left: 3px solid #F44E4E;
}

.inactiveTable {
  background: linear-gradient(to right, rgba(244, 78, 78, 0.25), rgba(244, 78, 78, 0), rgba(244, 78, 78, 0.25));
}

/* ---------------------------------------------------
GLOBAL CALENDAR
----------------------------------------------------- */
/* Planning Calendar  */
full-calendar table th {
  font-family: 'Urbane', sans-serif !important;
  color: white !important;
}

full-calendar table th span.fc-icon, 
  full-calendar table th span #costCenter {
  color: black !important;
}


/* Navigation Arrows */
mat-paginator, /* Dashboard: dimona error requests */
div.col-6 .mat-paginator, /* Dashboard: pending/to validate requests */
div.col-md-12 .mat-paginator  /* Operating Units */
{
  color: #F46250 !important;
}

/* Items per page & Pages */
.mat-paginator-page-size-label, /* "Items per page" */
.mat-paginator-page-size-value, /* Amount of items per page */
.mat-paginator-range-label /* Range pages viewed (ex: 1-5 of 15) */
{
  color: #1D2E48;
}


/* ---------------------------------------------------
GLOBAL LIST/NAV-TABS
----------------------------------------------------- */
/* Customers: customer view + customer edit */
ul.nav-tabs {
  border-bottom: 1px solid #F46250;
  font-family: 'Urbane', sans-serif;
}

/* Customers: customer view + customer edit */
li.nav-item .nav-link.active {
  color: #F46250;
  font-weight: 700;
  border-color: #F46250 #F46250 #fff;
}


/* ---------------------------------------------------
    GLOBAL DOCUMENTS
----------------------------------------------------- */
/* Document header  */
.orangeHead {
  background: #F46250;
}

/* HS Logo  */
.logoContract {
  height: 98px;
  background-color: #fff;
}

/* Document infos Orange header  */
span.headingOrange {
  font-size: 1em;
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  background-color: #F46250;
  color: #fff;
  width: 250px;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 5px;
  overflow: visible;
}

/* Document infos Blue 1 & 2 header */
span.headingBlue1, span.headingBlue2  {
  font-size: 80%;
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  color: #fff;
  width: 250px;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 5px;
  overflow: visible;
}

/* Document infos Blue 1 & 2 header colors */
span.headingBlue1 {
  background-color: #1D2E48;
}

span.headingBlue2 {
  background-color: #1D2E48;
}

/* Document infos Blue 1 & 2 border colors */
.blueBox1, .bluebox2 {
  padding-left: 0;
  border-radius: 4px;
  border: 1px solid #1D2E48;
}

/* Total orange - Contracts */
span.totalOrange1 {
  font-size: 1em;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  background-color: #F46250;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0 0;
  overflow: visible;
}

/* Total Orange 2 - Bills */
span.totalOrange2 {
  font-size: 1em;
  background-color: rgba(244,98,80, 0.25);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px solid #F46250;
  border-right: 1px solid #F46250;
  border-top: 1px dotted #F46250;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

span.totalOrange3 {
  font-size: 1em;
  font-weight: bold;
  font-family: 'Urbane', sans-serif;
  background-color: #F46250;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  border-bottom-right-radius: 3px;
  padding: 0 0;
  overflow: visible;
}

/* Total Blue 2 - Bills */
span.totalBlue2 {
  font-size: 1em;
  font-weight: 700;
  font-family: 'Urbane', sans-serif;
  background-color: #1D2E48;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  border-top-left-radius: 3px;
  overflow: visible;
}

/* Total Blue 3 - Bills */
span.totalBlue3 {
  font-size: 1em;
  background-color: rgba(29,46,72, 0.25);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px solid #1D2E48;
  border-right: 1px solid #1D2E48;
  border-top: 1px solid #1D2E48;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  border-top-right-radius: 3px;
  overflow: visible;
}

span.costHeadOrange {
  font-size: 1em;
  font-weight: bold;
  font-family: 'Urbane', sans-serif;
  background-color: rgba(244,98,80, 0.75);
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border: 2px solid #F46250;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

/* Week  */
span.weekOrange {
  font-size: 1em;
  font-weight: 700;
  background-color: rgba(244,98,80, 0.4);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  border-bottom-left-radius: 3px;
  padding: 0 0;
  overflow: visible;
}

/* Total Week - bills */
span.totalWeekOrange {
  font-size: 1em;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  color: #F46250;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border: 1px dotted #F46250;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

/* Day header - bills */
.dayWorkHeader {
  font-size: 1em;
  color: #F46250;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px dotted #F46250;
  border-right: 1px dotted #F46250;
  border-top: 1px dotted #F46250;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

/* Worker header - bills */
span.workHeadOrange {
  background-color: #F46250;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  padding: 0 0;
  overflow: visible;
}

/* Orange line - bills */
.billOrangeLine {
  padding-left: 0;
  border-bottom: 1px solid #F46250;
  font-weight: 700;
  font-size: 1em;
  font-family: 'Open Sans', sans-serif;
}

/* Header Orange line - bills */
span.billOrangeHeaderLine {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  overflow: visible;
}

/* Total detail Blue 1 & 2 - bills */
span.totalDetailBlue1, span.totalDetailBlue2 {
  font-size: 1em;
  font-weight: 700;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

/* Total detail Blue 1 colors - bills */
span.totalDetailBlue1 {
  background-color: rgba(29,46,72, 0.25);
  border-left: 1px solid #1D2E48;
  border-right: 1px solid #1D2E48;
}

/* Total detail Blue 2 colors - bills */
span.totalDetailBlue2 {
  background-color: rgba(108, 225, 243, 0.25);
  border-left: 1px solid #6ce1f3;
  border-right: 1px solid #6ce1f3;
}

/* Total Cost Center Orange 1 & 2 - bills */
span.totalCostCenterOrange1, span.totalCostCenterOrange2, span.totalCostCenterOrange3 {
  font-size: 1em;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border: 1px solid #F46250;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

/* Total Cost Center Orange 1 colors - bills */
span.totalCostCenterOrange1 {
background-color: rgba(244, 78, 78, 0.25);
}

/* Total Cost Center Orange 2 colors - bills */
span.totalCostCenterOrange2 {
  background-color: rgba(244, 78, 78, 0.10);
  }

/* Total Cost Center Orange 3 colors - bills */
span.totalCostCenterOrange3 {
  background-color: rgba(244,98,80, 1);
  color: white;
  }























































  /* ------------------------------------------------------------------     OLD   ------------------------------------------------------------------------------------- */

  span.totalRed2 {
    font-size: 1em;
    background-color: rgba(244, 78, 78, 0.25);
    width: 100%;
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
    border: none;
    border-left: 1px solid #F44E4E;
    border-right: 1px solid #F44E4E;
    border-top: 1px dotted #F44E4E;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 0;
    overflow: visible;
  }

  span.totalRed3 {
    font-size: 1em;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    background-color: #F44E4E;
    color: #fff;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
    border: none;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    border-bottom-right-radius: 3px;
    padding: 0 0;
    overflow: visible;
  }
  
/* ---------------------------------------------------
    GLOBAL RULES
----------------------------------------------------- */

.controls > input[readonly] {
  background-color: rgba(203, 203, 203, 0.15);
}

.controls > input[readonly]:hover {
  border: 2px dashed #c6c6c6;
}

body {
  background: #fafafa;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

// .verticalAlign {
//   display: table;
//   padding-top: 1.5em;
// }


// .headingGreenBox {
//   position: relative;
//   top: -19px;
//   padding-left: 5px;
//   padding-right: 7px;
//   display: table-cell;
//   background: #fff;
//   font-family: 'Ubuntu', sans-serif;
//   font-style: italic;
//   font-weight: 500;
//   font-size: 1.4em;
//   line-height: 35px;
//   color: #1D2E48;
// }

span.greenFooter {
  color: #1D2E48;
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Ubuntu', sans-serif;
}

span.blackFooter {
  color: #212529;
  font-size: 1.2em;
}

.colorline {
  border-bottom: 1px solid #F46250;
  margin-bottom: 33px;
}

// span.whiteBox {
//   display: table-cell;
//   vertical-align: middle;
//   font-size: 1em;
//   color: #fff;
//   border: 3px solid #fff;
//   padding: 10px 0px;
//   font-weight: 700;
//   font-family: 'Ubuntu', sans-serif;
//   text-transform: uppercase;
// }

span.redText {
  color: #F44E4E;
  font-size: 0.8em;
  font-weight: 400;
}

span.redText2 {
  color: #F44E4E;
  font-size: 1em;
  font-weight: 400;
}


span.headingRed {
  font-size: 1em;
  font-weight: 400;
  font-family: 'Ubuntu', sans-serif;
  background-color: #F44E4E;
  color: #fff;
  width: 162.61px;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 5px;
  overflow: visible;
}

.redbox {
  padding-left: 0;
  border-radius: 4px;
  border: 1px solid #F44E4E;
}

span.headingBlue1 {
  font-size: 1em;
  font-weight: 400;
  font-family: 'Ubuntu', sans-serif;
  background-color: #3ccbe2;
  color: #fff;
  width: 162.61px;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 5px;
  overflow: visible;
}

span.totalBlue1 {
  font-size: 1em;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  background-color: #3ccbe2;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  border-top-left-radius: 3px;
  overflow: visible;
}

span.totalBlue2 {
  font-size: 1em;
  background-color: rgba(108, 225, 243, 0.25);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px solid #3ccbe2;
  border-right: 1px solid #3ccbe2;
  border-bottom: 1px dotted #3ccbe2;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

span.totalBlue3 {
  font-size: 1em;
  font-weight: 700;
  background-color: rgba(108, 225, 243, 0.25);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px solid #3ccbe2;
  border-right: 1px solid #3ccbe2;
  border-bottom: 1px solid #3ccbe2;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  border-bottom-left-radius: 3px;
  padding: 0 0;
  overflow: visible;
}

span.totalDetailBlue1 {
  font-size: 1em;
  font-weight: 700;
  background-color: rgba(60, 203, 226, 0.25);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px solid #3ccbe2;
  border-right: 1px solid #3ccbe2;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

span.totalDetailBlue2 {
  font-size: 1em;
  font-weight: 700;
  background-color: rgba(108, 225, 243, 0.25);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px solid #6ce1f3;
  border-right: 1px solid #6ce1f3;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

.dayWorkHeader {
  font-size: 1em;
  color: #1D2E48;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px dotted #1D2E48;
  border-right: 1px dotted #1D2E48;
  border-top: 1px dotted #1D2E48;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

span.totalRed1 {
  font-size: 1em;
  background-color: rgba(244, 78, 78, 0.25);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px solid #F44E4E;
  border-right: 1px solid #F44E4E;
  border-top: 1px solid #F44E4E;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  border-top-right-radius: 3px;
  overflow: visible;
}

span.totalRed2 {
  font-size: 1em;
  background-color: rgba(244, 78, 78, 0.25);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px solid #F44E4E;
  border-right: 1px solid #F44E4E;
  border-top: 1px dotted #F44E4E;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

span.totalRed3 {
  font-size: 1em;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;
  background-color: #F44E4E;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  border-bottom-right-radius: 3px;
  padding: 0 0;
  overflow: visible;
}

span.totalRed4 {
  font-size: 1em;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;
  background-color: #F44E4E;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0 0;
  overflow: visible;
}

span.costHeadRed {
  font-size: 1em;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;
  background-color: rgba(244, 78, 78, 0.75);
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 2px solid #F44E4E;
  border-right: 2px solid #F44E4E;
  border-top: 2px solid #F44E4E;
  border-bottom: 2px solid #F44E4E;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

span.totalCostCenterRed1 {
  font-size: 1em;
  font-family: 'Ubuntu', sans-serif;
  background-color: rgba(244, 78, 78, 0.25);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px solid #F44E4E;
  border-right: 1px solid #F44E4E;
  border-top: 1px solid #F44E4E;
  border-bottom: 1px solid #F44E4E;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

span.totalCostCenterRed2 {
  font-size: 1em;
  font-family: 'Ubuntu', sans-serif;
  background-color: rgba(244, 78, 78, 0.10);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px solid #F44E4E;
  border-right: 1px solid #F44E4E;
  border-top: 1px solid #F44E4E;
  border-bottom: 1px solid #F44E4E;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

span.totalWeekRed {
  font-size: 1em;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;
  color: #F44E4E;;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  border-left: 1px dotted #F44E4E;
  border-right: 1px dotted #F44E4E;
  border-top: 1px dotted #F44E4E;
  border-bottom: 1px dotted #F44E4E;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 0;
  overflow: visible;
}

span.workHeadGreen {
  background-color: #1D2E48;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  padding: 0 0;
  overflow: visible;
}

.blueBox1 {
  padding-left: 0;
  border-radius: 4px;
  border: 1px solid #3ccbe2;
}

.billRedLine {
  padding-left: 0;
  border-bottom: 1px solid #F44E4E;
  font-weight: 700;
  font-size: 1em;
  font-family: 'Ubuntu', sans-serif;
}

span.billRedHeaderLine {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  overflow: visible;
}

span.headingBlue2 {
  font-size: 1em;
  font-weight: 400;
  font-family: 'Ubuntu', sans-serif;
  background-color: #6ce1f3;
  color: #fff;
  width: 162.61px;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 5px;
  overflow: visible;
}

.bluebox2 {
  padding-left: 0;
  border-radius: 4px;
  border: 1px solid #6ce1f3;
}

.alert-success {
  color: #087c8e;
  background-color: rgba(23, 162, 184, 0.25);
  border-color: #087c8e;
}

.badge-success {
  color: #fff;
  background-color: #1D2E48;
}


/* ---------------------------------------------------
    GLOBAL TABLES STYLE
----------------------------------------------------- */


th {
  font-weight: 400;
  text-align: -internal-center;
}



tbody {
  font-weight: 300;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid rgba(247, 125, 114, 0.5);
  border-top: 0px solid rgba(247, 125, 114, 0.5);
}

.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid rgba(247, 125, 114, 0.5);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(247, 125, 114, 0.05);
}



/* ---------------------------------------------------
    GLOBAL MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar.active {
    margin-left: 0;
  }

  #content {
    width: 100%;
  }

  #content.active {
    width: calc(100% - 250px);
  }

  #sidebarCollapse span {
    display: none;
  }
}


/*  FULLCALENDAR  */
.fc thead {
  background-image: linear-gradient(#F44E4E, #f37171);
  color: #fff;
}


// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* You can add global styles to this file, and also import other style files */

// html,
// body,
// body > * {
//   margin: 0;
//   padding: 0;
//   height: 100%;
// }

// body {
//   margin: 0;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
// }


// @include mat.core();

// $my-app-primary: mat.define-palette(mat.$cyan-palette, 600, 700, 800);
// $my-app-accent: mat.define-palette(mat.$blue-gray-palette, 50, 100, 400);
// $my-app-warn: mat.define-palette(mat.$red-palette, A400);
// $my-app-theme: mat.define-light-theme(
//     $my-app-primary,
//     $my-app-accent,
//     $my-app-warn
// );

// @include mat.all-component-themes($my-app-theme);

// .alternate-theme {
//   $alternate-primary: mat.define-palette(mat.$cyan-palette, 600, 700, 800);
//   $alternate-accent: mat.define-palette(mat.$amber-palette);
//   $alternate-warn: mat.define-palette(mat.$red-palette);
//   $alternate-theme: mat.define-light-theme(
//       $alternate-primary,
//       $alternate-accent,
//       $alternate-warn
//   );
//   @include mat.all-component-themes($alternate-theme);
// }

// .third-theme {
//   $third-primary: mat.define-palette(mat.$cyan-palette, 600, 700, 800);
//   $third-accent: mat.define-palette(mat.$orange-palette, 500);
//   $third-warn: mat.define-palette(mat.$red-palette, A400);
//   $third-theme: mat.define-light-theme(
//       $third-primary,
//       $third-accent,
//       $third-warn
//   );
//   @include mat.all-component-themes($third-theme);
// }


/* Rules for sizing the icon. */
// .material-icons.md-18 {
//   font-size: 18px;
// }

// .material-icons.md-24 {
//   font-size: 24px;
// }

// .material-icons.md-36 {
//   font-size: 36px;
// }

// .material-icons.md-48 {
//   font-size: 48px;
// }

/* Rules for using icons as black on a light background. */
// .material-icons.md-dark {
//   color: rgba(0, 0, 0, 0.54);
// }

// .material-icons.md-dark.md-inactive {
//   color: rgba(0, 0, 0, 0.26);
// }

/* Rules for using icons as white on a dark background. */
// .material-icons.md-light {
//   color: rgba(255, 255, 255, 1);
// }

// .material-icons.md-light.md-inactive {
//   color: rgba(255, 255, 255, 0.3);
// }

// .material-icons.md-18 {
//   font-size: 18px;
// }

// .material-icons.md-24 {
//   font-size: 24px;
// }

// .material-icons.md-36 {
//   font-size: 36px;
// }

// .material-icons.md-48 {
//   font-size: 48px;
// }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.customSnackValid {
  color: #1D2E48;
  background-color: #d8f4fb;
  border-style: solid;
  border-width: 1px;
  border-color: #1D2E48;
}

.customSnackValid .mat-simple-snackbar-action {
  color: #1D2E48;
}

.customSnackInvalid {
  color: #F46250;
  background-color: #fdccca;
  border-style: solid;
  border-width: 1px;
  border-color: #F46250;
}

.customSnackInvalid .mat-simple-snackbar-action {
  color: #F46250;
}

.customSnackError {
  color: #f3a53b;
  background-color: #fce8ce;
  border-style: solid;
  border-width: 1px;
  border-color: #f3a53b;
}

.customSnackError .mat-simple-snackbar-action {
  color: #f3a53b;
}


td.mat-cell {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.table {
  width: 100%;
  max-width: 100%;

  th,
  td {
    vertical-align: middle;
    text-align: center;
  }

  thead th {
    vertical-align: middle;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }

}



.orange {
  color: #FF9D00;
}

.red {
  color: #F44E4E;
}

.sick {
  color: #4258;
}

.mxs-1 {
  margin-left: 1px;
  margin-right: 1px;
}

.matFlex {
  display: flex;
  flex-direction: column;
}

.matFlex > * {
  width: 100%;
}

.matCardMapHeaderImage {
  background-image: url('/../../assets/img/GoogleMaps.jpg');
  background-size: cover;
}

.mat-form-field-empty.mat-form-field-label {
  color: #d0d0d0;
}

.headingMT {
  margin-top: 1.2em;
}

.buttonNMT {
  margin-top: -15px;
}

.tabsMT {
  margin-top: 1em;
}

.txtAreaH-5 {
  height: 5em;
}

.txtAreaH-18 {
  height: 18em;
}

.txtAreaH-23 {
  height: 23.6em;
}

.mat-form-field-subscript-wrapper {
  font-size: 65%;
  font-style: italic;
  margin-top: 0.33333334em;
  margin-bottom: 0.33333334em;
  top: calc(100% - 1.79166667em);
}

.mat-panel-customer-padding .mat-expansion-panel-body {
  padding: 14px 14px 24px 14px;
}

.mat-panel-costcenter-padding .mat-expansion-panel-body {
  padding: 0;
}

.mat-panel-worker-padding .mat-expansion-panel-body {
  padding: 0;
}

.mat-panel-day-padding .mat-expansion-panel-body {
  padding: 0;
}

.listLink:hover {
  cursor: pointer;
  background-color: rgba(202, 202, 202, 0.1);
}

.mat-form-field.no-padding > .mat-form-field-wrapper {
  margin-bottom: -1.25em;
}